import React, { useEffect } from "react";
import image from "../assets/traillingIcon.png";

export const BenfitsCard = ({ icon, mainText, secondaryText }) => {
  const test = { icon };

  useEffect(() => {
    console.log(icon);
    console.log(test);
    console.log(image);
  }, []);

  return (
    <div className="w-9/12 h-72 h-1/4 flex flex-col justify-center items-center bg-backgroundGray rounded-xl font-newFont my-10 py-4 md:w-1/5">
      <img src={icon} className="w-1/4" />
      <div className="w-10/12 mt-6">
        <h1 className="text-2xl font-black text-primaryBlue">{mainText}</h1>
        <p className="text text-primaryBlue mt-4">{secondaryText}</p>
      </div>
    </div>
  );
};
