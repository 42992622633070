import React from "react";
import logo from "../assets/logoa.png";

export const Navbar = () => {
  return (
    <div className="h-20 flex items-center">
      {/* <div className="bg-logo-pattern bg-no-repeat h-12 w-full "></div> */}
      <img src={logo} alt="" className="object-cover h-12 w-30 ml-4" />
    </div>
  );
};
