import React from "react";
import statBox from "../assets/grafico.png";
// import statBoxA from "../assets/grafico.jpg";

export const HeaderStatsComponent = () => {
  return (
    <div className="flex flex-col w-60 h-28 md:h-1/3 md:w-[63%] bg-white rounded-2xl absolute -bottom-4 md:bottom-2 right-0 drop-shadow-[0_7px_10px_rgba(62,19,77,0.2)]">
      <img src={statBox} className="h-full" />
    </div>
  );
};
