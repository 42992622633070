import React from "react";
import image from "../assets/stockbusinessman.png";
import { NewReportsCard } from "./NewReportsCard";
import { HeaderStatsComponent } from "./HeaderStatsComponent";

export const HeaderImageComponent = () => {
  return (
    <div className="md:w-full md:h-4/5 relative mt-12">
      <NewReportsCard />
      <img
        src={image}
        alt=""
        className="md:w-10/12 md:ml-10 w-[95%] drop-shadow-[0_7px_10px_rgba(62,19,77,0.2)] "
      />
      <HeaderStatsComponent />
    </div>
  );
};
