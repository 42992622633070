import React from "react";

export const InfoTextCard = ({
  blueText,
  whiteText,
  subText,
  mainText,
  index,
}) => {
  return (
    <div
      className={`h-96 md:h-64 w-11/12 md:w-[95%] mt-20 md:mt-12 font-newFont ${
        index === 0 ? "border-b border-slate-300" : null
      } md:flex ${
        index === 1 ? "md:flex-row-reverse md:mb-12" : null
      } md:justify-between md:items-center`}
    >
      <div className="">
        <div className="text-left mt-4">
          <h1 className="text-3xl text-primaryBlack font-black">
            {" "}
            <span className="text-primaryBlue"> {blueText} </span> {whiteText}
          </h1>
        </div>
        <div className="h-[3px] w-10 bg-primaryBlue mt-5"></div>
        <div className="text-left mt-4 font-newFont md:mt-8">
          <p className="text-xl text-primaryBlack">{subText}</p>
        </div>
      </div>
      <div className="text-left mt-4 font-newFont md:w-3/5">
        <p className="text-primaryBlack">{mainText}</p>
      </div>
    </div>
  );
};
