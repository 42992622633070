import React from "react";

export const NewReportsCard = () => {
  return (
    <div className="flex flex-col w-24 h-[74px] md:w-[150px] md:h-[110px] bg-white rounded-2xl absolute -top-6 md:-top-6 right-0 drop-shadow-[0_7px_10px_rgba(62,19,77,0.2)] z-10 justify-around py-3 items-center">
      <div className="font-newFont">
        <div className="text-[6px] md:text-[9px] font-semibold">
          Nuevos informes
        </div>
        <div className="text-[4px] md:text-[6px] text-slate-400 mt-0.5">
          Tienes informes disponibles
        </div>
      </div>
      <div className="w-1/5">
        <div className="absolute h-[3px] w-[3px] md:h-1 md:w-1 rounded-lg bg-red-500 top-7 left-14 md:top-11 md:left-20 "></div>
        <div className="text-sm md:text-lg font-medium m-0 p-0">5</div>
      </div>
      <div className="h-1/3 md:h-1/4 flex flex-col justify-center items-center mt-1n">
        <div className="flex w-9 h-3 md:w-[60px] md:h-10 bg-primaryBlue text-[5px] md:text-[8px] text-white rounded-sm justify-center items-center">
          Revisar
        </div>
      </div>
    </div>
  );
};
